export const getOutputVariables = (prompts) => {
  return prompts.map((prompt) => {
    const promptWithOutputVariable = prompt.variables?.find((variable) => variable.input === false)

    if (promptWithOutputVariable)
      return {
        id: prompt.id,
        name: promptWithOutputVariable.name,
        value: prompt?.output?.[prompt?.model?.name]?.responseText ?? ''
      }

    return {
      id: prompt.id
    }
  })
}
