import { OPEN_VAULT } from "../actions/actionConstants";

const openVault = (state = null, action) => {
  switch (action.type) {
    case OPEN_VAULT:
      return action.payload
    default:
      return state
  }
}

export default openVault
