import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Auth from './../views/Auth/Auth'
import { NonAuthRoute } from './../Routes/RouteHandler'

const NonAuthRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <NonAuthRoute>
            <Auth />
          </NonAuthRoute>
        }
      />
    </Routes>
  )
}

export default NonAuthRoutes
