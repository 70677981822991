import { combineReducers } from 'redux'
import isAuthenticated from './isAuthenticated'
import userDetails from './userDetails'
import openVault from './vault'
import chainList from './chainList'
import availableModels from './availableModels'
import outputVariables from './variables'

const rootReducer = combineReducers({
  isAuthenticated,
  userDetails,
  openVault,
  chainList,
  availableModels,
  outputVariables
})

export default rootReducer
