import React, { lazy } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import { AuthRoute } from './RouteHandler'
import { ROUTES } from '../store/constants/routes'
import OnboardUserPage from '../components/Modals/WelcomeModal'

const Layout = lazy(() => import('./Layout'))
const BuildScreen = lazy(() => import('../views/Build/BuildScreen'))
const Monitor = lazy(() => import('../views/Monitor/Monitor'))
const Databases = lazy(() => import('../views/Databases/Databases'))

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.ONBOARD_USER} element={<OnboardUserPage />} />
      <Route
        element={
          <AuthRoute>
            <Layout />
          </AuthRoute>
        }
      >
        <Route index element={<Navigate to={ROUTES.BUILD} />} />
        <Route path={ROUTES.BUILD} element={<BuildScreen />} />
        <Route path={ROUTES.MONITOR} element={<Monitor />} />
        <Route path={ROUTES.DATABASES} element={<Databases />} />
      </Route>
    </Routes>
  )
}

export default AuthRoutes
