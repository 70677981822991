import { CHAIN_LIST } from '../actions/actionConstants'

const chainList = (state = null, action) => {
  switch (action.type) {
    case CHAIN_LIST:
      return action.payload

    default:
      return state
  }
}

export default chainList
