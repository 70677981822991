import { MODELS } from "../actions/actionConstants";

const availableModels = (state = null, action) => {
  switch (action.type) {
    case MODELS:
      return action.payload
    default:
      return state
  }
}

export default availableModels
