import { Fragment, useRef, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { FaCheck } from 'react-icons/fa6'
import { FaCaretDown } from 'react-icons/fa'
import { Controller, useFormContext } from 'react-hook-form'

const defaultOptions = [
  'Product Manager',
  'Founder / CEO / CTO / Executive',
  'Software Developer / ML Engineer / AI Engineer',
  'Other'
].map((n) => ({ label: n, value: n }))

export default function Autocomplete({ name, options = defaultOptions, placeholder, required = false }) {
  const [query, setQuery] = useState('')
  const { control, formState } = useFormContext()
  const buttonRef = useRef(null)

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().replace(/\s+/g, '').includes(query?.toLowerCase()?.replace(/\s+/g, ''))
        )

  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({ field }) => (
        <Combobox {...field}>
          <div className="relative mt-1 w-full">
            <div
              className={`mt-1.5 w-full bg-[#1e1e1e] rounded-md border border-[#545454] relativecursor-default overflow-hidden  text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-400 sm:text-sm ${
                formState.errors[name] ? 'focus:ring-red-500 focus:ring-1' : ''
              }`}
            >
              <Combobox.Input
                placeholder={placeholder}
                className="w-full border-none bg-inherit p-3 pr-3 leading-5 text-white focus:ring-0"
                displayValue={(option) => option}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button ref={buttonRef} className="absolute inset-y-0 right-0 flex items-center pr-2">
                <FaCaretDown className="h-5 w-5 mr-1 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-[#333] py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                {filteredOptions.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-400">Nothing found.</div>
                ) : (
                  filteredOptions.map((option, i) => (
                    <Combobox.Option
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-blue-500 text-white' : 'text-white'
                        }`
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block text-start truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {option.label}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-blue-500'
                              }`}
                            >
                              <FaCheck className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      )}
    />
  )
}
