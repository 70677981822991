import mixpanel from 'mixpanel-browser'
import { MIXPANEL_TOKEN, IS_DEV } from '../../config'

const initAnalytics = () => {
  if (IS_DEV) return
  ;(function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        ;(c[a].q = c[a].q || []).push(arguments)
      }
    t = l.createElement(r)
    t.async = 1
    t.src = 'https://www.clarity.ms/tag/' + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
  })(window, document, 'clarity', 'script', 'j9tm20uuzf')

  mixpanel.init(MIXPANEL_TOKEN, { track_pageview: true, ignore_dnt: true, persistence: 'localStorage' })
}

initAnalytics()

export const trackEvent = (event, properties) => {
  if (IS_DEV) return
  mixpanel.track(event, properties)
}

export const identifyUser = (id) => {
  if (IS_DEV) return

  mixpanel.identify(id)
}

export const trackPage = () => {
  if (IS_DEV) return

  mixpanel.track_pageview()
}

export const setUserAlias = (id) => {
  if (IS_DEV) return

  mixpanel.alias(id)
}

export const resetAnalytics = () => {
  if (IS_DEV) return

  mixpanel.reset()
}
