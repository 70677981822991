import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SharedPromptView from '../views/SharedPrompt'
import { ROUTES } from '../store/constants/routes'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.SHARE} element={<SharedPromptView />} />
    </Routes>
  )
}

export default PublicRoutes
