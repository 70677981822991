// * TODO: Convert to Typescript Project

export const EVENTS = {
  CLICKED_SIGNUP: 'Clicked Signup',
  CLICKED_LOGIN: 'Clicked Login',
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',
  NEW_CHAIN_CREATED: 'New Prompt chain created',
  UPLOAD_CSV: 'Uploaded CSV',
  IMPORT_CSV_CLICKED: 'Imported CSV Clicked',
  PROMPT_UPDATED: 'Prompt Updated',
  ADDED_PROMPT: 'Added Prompt',
  ASSERTION_ADDED: 'Assertion Added',
  ASSERTION_UPDATED: 'Assertion Updated',
  ASSERTION_DELETED: 'Assertion Deleted',
  RUNNING_PROMPT_CHAIN: 'Running Prompt Chain',
  RUN_PROMPT_CHAIN_CLICKED: 'Run Button Chain Clicked',
  ERROR_RUNNING_PROMPT_CHAIN: 'Error Running Prompt Chain',
  ONBOARDING_USER_DETAILS_SUBMISSION: 'Onboarding User Details Submission',
  ONBOARD_USER_PAGE_SKIPPED: 'Onboard User Page Skipped'
}
