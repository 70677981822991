import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'

const Button = ({ delay, className = '', label, onClick }) => {
  const [showButtonAfterDelay, setShowButtonAfterDelay] = useState(false)

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setShowButtonAfterDelay(true)
      }, delay)
    }
  }, [])

  return (
    <Transition
      show={showButtonAfterDelay}
      enter="transition-opacity duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <button className={className} onClick={onClick}>
        {label}
      </button>
    </Transition>
  )
}

export default Button
