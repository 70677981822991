import axios from 'axios'
import { store } from './index'
import { BASE_URL } from './config'

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 40000
})

export default instance

instance.interceptors.request.use(
  async (config) => {
    config.headers['x-auth-token'] = store.getState()?.userDetails?.token
    config.headers['Authorization'] = 'sk-dl33q9c1jul6zole12og5dbn255j48d2'
    config.headers['Access-Control-Allow-Origin'] = '*'

    // console.log(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
