import { Fragment, useEffect, useState } from 'react'

import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import Logo from '../../assets/svgs/logo.svg'

import { trackEvent } from '../../services/analytics'
import { EVENTS } from '../../services/analytics/events'
import { store } from '../..'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../store/constants/routes'
import Autocomplete from '../Autocomplete'
import Button from '../Button'

const Option = ({ label }) => {
  const { register, formState } = useFormContext()

  return (
    <div className="flex items-center">
      <input
        id={label}
        type="checkbox"
        name={label}
        value={label}
        {...register('areas-solving', { required: true })}
        className={`w-5 h-5 text-[#276ef1] rounded focus:ring-offset-0 focus:ring-0 focus:border-gray-400 border-gray-500 transition-[border] bg-[#333] ${
          formState.errors['areas-solving'] ? 'focus:ring-red-500 focus:ring-1' : ''
        }`}
      />
      <label for={label} className="w-full p-2 ms-2 text-gray-300 select-none ">
        {label}
      </label>
    </div>
  )
}

const RoleContainer = () => {
  const { register, formState, watch } = useFormContext()
  const watchAutocomplete = watch('role', false)

  return (
    <>
      <Autocomplete name="role" required />
      {watchAutocomplete === 'Other' && (
        <input
          autoFocus
          placeholder="Tell use your role"
          className={`mt-1.5 p-3 w-full bg-[#1e1e1e] rounded-md border border-[#545454] focus:outline-none focus:border-gray-400 text-white transition-[border] focus:ring-0 ${
            formState.errors['Tell us your role'] ? 'focus:ring-red-500 focus:ring-1' : ''
          }`}
          id="knit-ai-org-form-field"
          type="text"
          {...register('Tell us your role', { required: true })}
        />
      )}
    </>
  )
}

const OnboardingForm = ({ onClose }) => {
  const formMethods = useForm()
  const [showOtherAreas, setShowOtherAreas] = useState(false)

  const markUserAsOnboarded = () => {
    const userId = store.getState().userDetails.id
    localStorage.setItem('knit-ai-onboarding-form', userId)
  }

  const onSubmit = (data) => {
    trackEvent(EVENTS.ONBOARDING_USER_DETAILS_SUBMISSION, data)
    markUserAsOnboarded()
    onClose()
  }

  const handleSkip = () => {
    const fields = formMethods.getValues() ?? {}
    trackEvent(EVENTS.ONBOARD_USER_PAGE_SKIPPED, fields)

    markUserAsOnboarded()
    onClose()
  }

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      const fieldName = 'areas-solving'
      if (name === fieldName) {
        setShowOtherAreas(value[fieldName].includes('Other'))
      }
    })
    return () => subscription.unsubscribe()
  }, [formMethods.watch])

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <FormProvider {...formMethods}>
        <div className="mb-5">
          <label className="block text-white font-bold mb-1" for="organization">
            Your Organization *
          </label>
          <input
            autoFocus
            className={`mt-1.5 p-3 w-full bg-[#1e1e1e] rounded-md border border-[#545454] focus:outline-none focus:border-gray-400 text-white transition-[border] focus:ring-0 ${
              formMethods.formState.errors['organization'] ? 'focus:ring-red-500 focus:ring-1' : ''
            }`}
            id="knit-ai-org-form-field"
            type="text"
            {...formMethods.register('organization', { required: true })}
          />
        </div>
        <div className="mb-5">
          <label className="block text-white font-bold mb-1" for="role">
            Your role *
          </label>
          <RoleContainer />
        </div>
        <div className="mb-16 text-[#eee] ">
          <p className="mb-1">What important areas can Knit help you solve? (Select all that applies) *</p>
          <div className="flex-col">
            <Option label="Evaluating LLMs" />
            <Option label="Building chains" />
            <Option label="Building, testing and managing prompts" />
            <Option label="Monitoring and user feedback" />
            <Option label="Just exploring" />
            <Option label="Other" />
            {showOtherAreas && (
              <input
                className={`mt-1.5 p-3 w-full bg-[#1e1e1e] rounded-md border border-[#545454] focus:outline-none focus:border-gray-400 text-white transition-[border] focus:ring-0 ${
                  formMethods.formState.errors['Tell us what we can help with'] ? 'focus:ring-red-500 focus:ring-1' : ''
                }`}
                placeholder="Tell us what we can help with"
                id="knit-ai-help-form-field"
                type="text"
                {...formMethods.register('Tell us what we can help with', { required: true })}
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="rounded-md px-2 py-2 font-semibold shadow-sm sm:w-[150px] bg-blue-500 text-[#eee] hover:bg-[#276eff]"
            type="submit"
          >
            Submit
          </button>
          <Button delay={1000} label="skip" onClick={handleSkip} className=" text-gray-500 opacity-80" />
        </div>
      </FormProvider>
    </form>
  )
}

export default function OnboardUserPage() {
  const navigate = useNavigate()

  return (
    <section className="flex items-center justify-center h-screen">
      <div className="flex flex-col gap-6">
        <img src={Logo} alt="Logo" className="w-20" />
        <span className="font-bold leading-6 text-white flex items-center text-4xl tracking-wide">
          Welcome to Knit AI
        </span>
        <div className="text-[#eee]">Tell us a bit about yourself so we can help you out better.</div>
        <OnboardingForm onClose={() => navigate(ROUTES.BUILD, { replace: true })} />
      </div>
    </section>
  )
}
